<script setup>
  import { Notification, NotificationGroup } from 'notiwind'

  const top = useTop()
</script>

<template>
  <NotificationGroup group="main">
    <div
      class="pointer-events-none fixed inset-x-0 bottom-0 top-16 z-30 flex flex-col items-center space-y-4 p-4 duration-500"
      :class="[top ? 'md:top-20' : 'md:top-16']"
    >
      <Notification
        v-slot="{ close, notifications }"
        enter-from="opacity-0 -translate-y-full"
        enter-to="opacity-100 translate-y-0"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="pointer-events-auto flex w-full max-w-sm rounded bg-white/90 shadow backdrop-blur duration-500"
          v-for="notification in notifications"
          :key="notification.id"
          @click="close(notification.id)"
        >
          <div
            class="flex items-center justify-center rounded-l px-4 py-2 text-white"
            v-if="['danger', 'info', 'success'].includes(notification.type)"
            :class="{
              'bg-orange-500': notification.type === 'danger',
              'bg-blue-500': notification.type === 'info',
              'bg-green-400': notification.type === 'success'
            }"
          >
            <FontAwesomeIcon v-if="notification.type === 'danger'" icon="fa-solid fa-x" fixedWidth size="xl" />
            <FontAwesomeIcon v-else-if="notification.type === 'info'" icon="fa-solid fa-info" fixedWidth size="xl" />
            <FontAwesomeIcon v-else-if="notification.type === 'success'" icon="fa-solid fa-check" fixedWidth size="xl" />
          </div>

          <div class="space-y-2 px-4 py-2">
            <div
              class="font-special text-lg font-bold"
              v-html="notification.title"
              v-if="notification.title"
              :class="{
                'text-orange-500': notification.type === 'danger',
                'text-blue-500': notification.type === 'info',
                'text-green-400': notification.type === 'success'
              }"
            />
            <p v-html="notification.text" v-if="notification.text" />
          </div>
        </div>
      </Notification>
    </div>
  </NotificationGroup>
</template>
